@font-face {
  font-family: "Poppins-Regular";
  src: url("../../../Assets/fonts/Poppins-Regular.ttf");
}

:root {
  --black: #000000;
  --white: #ffffff;
  --secondary: #818181;
  --primary: #ffffff;
  --darkblack: #1e1e25;
  --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
  --darkGreyColor: rgba(255, 255, 255, 0.5);
  --white80: #ffffff80;
  --white50: #ffffff50;
  --grey50Rga: rgba(255, 255, 255, 0.5);
  --font24: "";
}

.text_grey_title {
  font-family: Poppins-Regular;
  font-size: 0.8rem;
  font-weight: 500;
  flex-shrink: 0;
  flex: 1;
  color: var(--secondary);
  position: relative;
}

.session_main_div_container {
  align-self: stretch;
  flex: 1;
  border-radius: 16px;
  background-color: #25252d;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.session_main_div_parent {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem 0 0.5rem;
}

.session_main_div_third_parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem 1rem;
  gap: 1rem;
}

.session_custom_dropdown_div_container {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1); /* Assuming you have a variable defined for the border color */
  box-sizing: border-box;
  width: 9.38rem;
  display: flex;
  z-index: 1;
  padding: 0.38rem 0.75rem;
}

.session_border_top {
  align-self: stretch;
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
}

.session_title_div_continer {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
}

.session_details_main_Div_View {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0.5rem;
  font-size: 1rem;
}
