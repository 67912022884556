@font-face {
  font-family: "Poppins-Regular";
  src: url("../Assets/fonts/Poppins-Regular.ttf");
}

:root {
  --black: #000000;
  --white: #ffffff;
  --secondary: #818181;
  --primary: #ffffff;
  --darkblack: #1e1e25;
  --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
  --darkGreyColor: rgba(255, 255, 255, 0.5);
  --white80: #ffffff80;
  --white50: #ffffff50;
  --grey50Rga: rgba(255, 255, 255, 0.5);
  --font24: "";
  --pagonationBackground: "rgba(255, 255, 255, 0.1)";
}

.mainTitleShow24 {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}

.contentTitle24Weight40 {
  flex: 1;
  font-family: "Poppins-Regular";
  font-size: 1rem;
  font-weight: 400;
  color: var(--white);
  position: relative;
}

.darkGreyFont16 {
  font-family: "Poppins-Regular";
  font-size: 0.8rem;
  font-weight: 400;
  flex: 1;
  color: var(--grey50Rga);
  position: relative;
}
.title24Weight500 {
  align-self: "stretch";
  position: "relative";
  font-size: 2rem;
  font-weight: 500;
  color: var(--white);
  font-family: "Poppins-Regular";
}

.title24WeightGraph {
  font-family: "Poppins-Regular";
  font-size: 1rem;
  font-weight: 500;
  color: var(--white);
  position: relative;
}

.title24Weight600 {
  font-family: "Poppins-Regular";
  font-size: 0.8rem;
  font-weight: 600;
  display: "inline-block";
  flex-shrink: "0";
  flex: "1";
  color: var(--white);
  position: relative;
}

.title16Weight400 {
  display: "flex";
  flex-direction: "row";
  align-items: "center";
  justify-content: "flex-start";
  gap: 0.5rem;
  font-size: "0.88rem";
  color: var(--grey50Rga);
}

.title24 {
  font-size: 1rem;
  font-family: "Poppins-Regular";
}

.title24FontWeight400WHite {
  font-family: "Poppins-Regular";
  font-size: 1rem;
  font-weight: 400;
  color: var(--white);
  position: relative;
}

.scrollable-container {
  max-height: 230px;
  overflow-y: scroll;
  overflow-x: hidden; 
  width: 100%;
  scrollbar-width: auto; 
  -ms-overflow-style: none; 
  position: relative;
}

.scrollable-container::-webkit-scrollbar {
  position: absolute;
  width: 0px; 
  background-color: transparent; 
}
.scrollable-container::-webkit-scrollbar-thumb {
  background-color: var(
    --tabbackgroundcolor
  );
  border-radius: 50px;
}

.fontSize24 {
  flex: 1;
  font-family: "Poppins-Regular";
  font-size: 1rem;
  font-weight: 500;
  color: var(--white);
  position: relative;
}

.fontSize16 {
  font-family: "Poppins-Regular";
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--white);
  flex: 1;
  position: relative;
}

.fontSize20 {
  font-size: 1.05rem;
  font-weight: 600;
  color: var(--white);
  flex: 1;
  position: relative;
}

.fontSize16WithoutFlex {
  font-family: "Poppins-Regular";
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--white);
  position: relative;
}

.fontSize18 {
  position: relative;
  font-family: Poppins-Regular;
  font-size: 0.9rem;
  font-weight: 400;
  color: #fff;
}

.fontSize18Weight400 {
  position: relative;
  font-family: Poppins-Regular;
  font-size: 0.8rem;
  font-weight: 400;
  color: #fff;
}
.fontSize14 {
  font-family: Poppins-Regular;
  font-size: 0.88rem;
  color: var(--white);
}

.fontSize32 {
  align-self: "stretch";
  position: "relative";
  font-size: 2rem;
  font-weight: 600;
  color: var(--white);
  font-family: "Poppins-Regular";
}

.ic_sorting {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
}

/* Pagination Container */
.pagination {
  /* display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0; */

  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
}


.pagination a {
  margin: 0 5px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: var(--white);
}


/* Pagination Item */
/* .pagination li {
  margin: 0 5px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: var(--white);
} */

/* Active Page Item */

.pagination__link--active a  {
  font-weight: bold;
  height: 40px;
  width: 40px;
  display: flex;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: var(--tabbackgroundcolor);
  color: var(--white);
}

/* Previous and Next Buttons */
.pagination .previous,
.pagination .next {
  margin: 0 10px;
  cursor: pointer;
}







.scrollable-container-scrollbar {
  max-height: 230px;
  overflow-y: scroll;
  overflow-x: hidden; 
  width: 100%;
  scrollbar-width: auto; 
  -ms-overflow-style: none; 
  position: relative;
}

.scrollable-container-scrollbar::-webkit-scrollbar {
  position: absolute;
  width: 1px; 
  background-color: transparent; 
}
.scrollable-container-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(
    --white
  );
  border-radius: 50px;
}





