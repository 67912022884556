.myCustomStyle {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  display: flex;
  flex: 0.4;
  align-self: stretch;
  flex-direction: column;
}

.myCustomStyle2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
}

.myCustomButton {
  border-radius: 46px;
  background-color: rgba(46, 144, 250, 0.1);
  border: 1px solid #2e90fa;
  box-sizing: border-box;
  width: 6.34rem;
  height: 1.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.38rem 1rem;
  font-size: 0.75rem;
  color: #2e90fa;
}

/* Outer <div> */
.outer-div {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.75rem 1rem;
}

/* Inner <div> */
.inner-div {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatMainDiv {
  overflow: hidden;
  flex: 1;
  height: 100%;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  align-self: stretch;
  padding: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatParentDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
}

.chatMsgBorder {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem 0.75rem;
}

.chatLastMsgDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 0.5rem;
}

.chatLastMsgParentDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 0.13rem;
}

.chatDayText {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}
