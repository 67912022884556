@font-face {
  font-family: "Poppins-Regular";
  src: url("../../Assets/fonts/Poppins-Regular.ttf");
}

:root {
  --black: #000000;
  --white: #ffffff;
  --secondary: #818181;
  --primary: #ffffff;
  --darkblack: #1e1e25;
  --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
  --darkGreyColor: rgba(255, 255, 255, 0.5);
  --white80: #ffffff80;
  --white50: #ffffff50;
  --grey50Rga: rgba(255, 255, 255, 0.5);
  --font24: "";
  --pagonationBackground: "rgba(255, 255, 255, 0.1)";
}
.headerContainer {
  padding-bottom: 20px;
  background-color: var(--darkblack);
}

/* Header Flex Container */
.headerFlexContainer {
  align-self: stretch;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

/* Header Text */
.headerText {
  flex: 1;
  font-family: Poppins-Regular;
  position: relative;
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--white);
}

/* Header Icons Container */
.headerIconsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 1.13rem;
}

/* Header Notification Icon */
.headerNotificationIcon {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

/* Header Dropdown Container */
.headerDropdownContainer {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  width: 9rem;
  z-index: 1;
}

/* Header Dropdown Label */
.headerDropdownLabel {
  /* Define specific styles for the dropdown label if needed */
}

/* Header CustomDropdown (Assuming CustomDropdown is a component) */
.headerCustomDropdown {
  /* Define specific styles for the CustomDropdown component if needed */
}

.modalContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.modal-absolute-div {
  position: absolute;
  background: transparent;
  height: 100%;
  width: 100%;
}

.modal-parent-div {
  z-index: 999;
  background: #393942;
  width: 30%;
  /* height: 90%; */
  padding-bottom: 10px;
  height:fit-content;
  border-radius: 10px;
}

.modal-render-item-div{
  display: flex;
gap: 0.5rem;
padding-top: 0.5rem;
align-items: center;

}

.modal-user-image{
  height: 2.5rem;
width: 2.5rem;
object-fit: cover;

}

.modal-white-round{
  left: 1rem;
background: white;
width: 0.5rem;
height: 0.5rem;
border-radius: 0.5rem;

}

.notification-red-round{
  background: red;
height: 0.5rem;
width: 0.5rem;
border-radius: 50%;
position: absolute;
top: 0.4rem;
right: 0.7rem;
display: flex;
justify-content: center;
align-items: center;

}