@font-face {
  font-family: "Poppins-Regular";
  src: url("../../../../Assets/fonts/Poppins-Regular.ttf");
}

:root {
  --black: #000000;
  --white: #ffffff;
  --secondary: #818181;
  --primary: #ffffff;
  --darkblack: #1e1e25;
  --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
  --darkGreyColor: rgba(255, 255, 255, 0.5);
  --white80: #ffffff80;
  --white50: #ffffff50;
  --grey50Rga: rgba(255, 255, 255, 0.5);
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 6 equal-width columns */
  gap: 0.5rem; /* Adjust the gap as needed */
  width: 100%;
}

.card-session-container {
  flex: 1;
  border-radius: 16px;
  background-color: #24242d;
  display: flex;
  font-family: Poppins-Regular;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  gap: 0.5rem;
  width: 100%;
  box-sizing: border-box;
}

.card-session-image-container {
  position: relative;
  width: 10rem;
  object-fit: cover;
  height: 12rem;
  font-family: Poppins-Regular;
}

.card-session-image {
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.card-session-image-overlay {
  position: absolute;
  width: 100%;
  right: 0rem;
  font-family: Poppins-Regular;
  bottom: 0rem;
  left: 0rem;
  background: linear-gradient(180deg, rgba(36, 36, 45, 0), #24242d);
  height: 12.19rem;
}

.card-session-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Poppins-Regular;
}

.card-session-name {
  position: relative;
  font-family: Poppins-Regular;
}

.card-session-duration {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Poppins-Regular;
  font-weight: 500;
  font-size: 0.65rem;
  color: #f79009;
}

.card-session-stats {
  display: flex;
  flex-direction: row;
  font-family: Poppins-Regular;
  justify-content: center;
  gap: 1rem;
  font-weight: 400;
  font-size: 0.65rem;
  color: #12b76a;
}

.card-session-stat {
  border-radius: 27px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  font-family: Poppins-Regular;
  gap: 0.25rem;
}

.card-session-icon {
  position: relative;
  width: 0.7rem;
  height: 0.7rem;
  overflow: hidden;
  font-family: Poppins-Regular;
  flex-shrink: 0;
}

.card-session-icon-watch {
  position: relative;
  width: 0.9rem;
  height: 0.9rem;
  display: flex;
  justify-content: center;
  font-family: Poppins-Regular;
}

.dateTime-item {
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  background: var(--tabbackgroundcolor);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
