@font-face {
  font-family: "Poppins-Regular";
  src: url("../../../Assets/fonts/Poppins-Regular.ttf");
}

:root {
  --black: #000000;
  --white: #ffffff;
  --secondary: #818181;
  --primary: #ffffff;
  --darkblack: #1e1e25;
  --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
  --darkGreyColor: rgba(255, 255, 255, 0.5);
  --white80: #ffffff80;
  --white50: #ffffff50;
  --grey50Rga: rgba(255, 255, 255, 0.5);
  --font24: "";
}

.mainContainerSessionView {
  flex: 1;
  align-self: stretch;
  border-radius: 16px;
  padding: 10px;
  border: 1px solid var(--tabbackgroundcolor);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.borderTopStyle {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--tabbackgroundcolor);
  box-sizing: border-box;
  height: 0.06rem;
}
.mainPersonalInformationContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
  align-items: flex-start;
  justify-content: flex-start;
}

.experticeTitle {
  border-radius: 74px;
  border: 1px solid var(--tabbackgroundcolor);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
}
.sectionDivStyle {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}

.contentName {
  font-family: "Poppins-Regular";
  font-size: 0.8rem;
  font-weight: 600;
  display: "inline-block";
  flex-shrink: "0";
  flex: "1";
  color: var(--white);
  position: relative;
}


/* custom-styles.css */
.react-tel-input .form-control:hover {
  color: red;

  border-color: rgba(255, 255, 255, 0.3);
  background-color: rgba(30, 30, 37, 0.8);
}
