@font-face {
  font-family: "Poppins-Regular";
  src: url("../../../Assets/fonts/Poppins-Regular.ttf");
}

:root {
  --black: #000000;
  --white: #ffffff;
  --secondary: #818181;
  --primary: #ffffff;
  --darkblack: #1e1e25;
  --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
  --darkGreyColor: rgba(255, 255, 255, 0.5);
  --white80: #ffffff80;
  --white50: #ffffff50;
  --grey50Rga: rgba(255, 255, 255, 0.5);
  --darkBorderColor: 1px solid rgba(255, 255, 255, 0.1);
}

.icon_style_list {
  position: relative;
  width: 1.1rem;
  height: 1.1rem;
  overflow: hidden;
  flex-shrink: 0;
}

.itemTextDisplay {
  font-family: Poppins-Regular;
  font-size: 0.8rem;
  font-weight: 400;
  color: #fff;
}

/* Define a custom style class */
.custom-box-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 1rem;
  color: var(--grey50Rga);
}

.custom-container-dropdown-div {
  border-radius: 8px;
  border: var(--darkBorderColor);
  block-size: border-box;
  width: 9.38rem;
  display: flex;
  z-index: 1;
  padding: 0.5rem 0.5rem;
}

.custom-box-search-div {
  border-radius: 8px;
  border: var(--darkBorderColor);
  box-sizing: border-box;
  width: 15.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 0.5rem;
  gap: 0.5rem;
}

.custom-image {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
}

.list-user-div-container {
  border-radius: 16px;
  border: var(--darkBorderColor);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 1rem;
}

.list-user-div-parent-container-coupon {
  background-color: #25252d;
  height: 3.56rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  align-self: stretch;
  gap: 0.5rem;
}

.modal-main-container {
  z-index: 999;
  background: #393942;
  width: 90%;
  padding: 20px;
  border-radius: 10px;
}

.modal-ic_close {
  align-self: center;
  justify-content: center;
  display: flex;
  height: 1.3rem;
  width: 1.3rem;
  object-fit: contain;
}
.modal-main-div {
  display: flex;
  flex: 1;
  padding-top: 1rem;
  flex-direction: column;
}
.modal-main-input {
  background: transparent;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  height: 1.8rem;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  outline: none;
}

.modal-coupon-code-type {
  display: flex;
  align-items: center;
  margin-top: 5px;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.modal-dropdown {
  background: transparent;
  display: flex;
  flex: 0.9;
  height: 1.8rem;
  padding-left: 1rem;
  color: white;
  outline: none;
  border: 0px solid rgba(255, 255, 255, 0.2);
}
.modal-dropdown::placeholder {
  color: #FFFFFF33;
}

.modal-second-div-title {
  display: flex;
  flex: 1;
  padding-top: 0.5rem;
  flex-direction: column;
}

.modal-from-to-div {
  background: transparent;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  height: 1.8rem;
  flex: 1;
  align-items: center;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  gap: 1rem;
}

.modal-from-to-icon {
  width: 1.2rem;
  height: 1.2rem;
  object-fit: cover;
}

.modal-second-dropdown {
  align-self: stretch;
  flex: 0.7;
  background: transparent;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  height: 1.8rem;
  color: white;
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 30px;
}
.modal-second-dropdown::placeholder {
  color: #FFFFFF33;
}

.modal-create-btn {
  border-radius: 20px;
  padding: 0.4rem;
  justify-content: center;
  display: flex;
  background: white;
  color: #1e1e25;
}
.modal-cancel-button {
  border-radius: 20px;
  padding: 0.4rem;
  justify-content: center;
  display: flex;
  border: 1px solid white;
  color: #fff;
}

.modal-clock-div {
  background: transparent;
  display: flex;
  padding-left: 10px;
  flex: 0.4;
  padding-right: 10px;
  margin-top: 5px;
  align-items: center;
  height: 1.8rem;
  color: #fff; /* Assuming Colors.white represents the color white */
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  gap: 1rem;
}

.date-picker-main-div {
  align-self: stretch;
  display: flex;
  flex: 0.4;
  height: 2rem;
  margin-top: 5px;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.custom-time-picker-style {
  font-size: 13x;
}

.date-outside-div {
  align-self: stretch;
  display: flex;
  flex: 0.6;
  height: 2rem;
  margin-top: 5px;
  align-items: center;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  z-index: 1;
}
.date-display-flex {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 1rem;
}

.date-picker-date-show {
  padding-left: 10px;
  align-self: stretch;
  align-items: center;
  display: flex;
  height: 2rem;
  gap: 0.5rem;
}

.time-clock {
  font-size: 12px; /* Adjust the font size to your desired value */
  height: 10rem;
  width: 10rem;
  /* Other styling properties as needed */
}





.date-picker-border-modal-dropdown {
  position: relative; /* Ensure relative positioning */
  background: transparent;
  display: flex;
 
  height: 1.5rem;
  /* width: 100px; */
  /* padding-left: 10px;padding-right: 40px; */
  
  border-radius: 30px;
  /* padding-left: 1rem; */
  color: white;
  /* margin: 10px; */
  /* right: 10px; */
  outline: none;
  /* padding: '6px 10px 5px 25px'; */
  border: 1px solid rgba(255, 255, 255, 0.2);
}




.data_test .react-datepicker__view-calendar-icon input{
  padding: 6px 27px 5px 9px;
}