@font-face {
  font-family: "Poppins-Regular";
  src: url("../../../../Assets/fonts/Poppins-Regular.ttf");
}

:root {
  --black: #000000;
  --white: #ffffff;
  --secondary: #818181;
  --primary: #ffffff;
  --darkblack: #1e1e25;
  --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
  --darkGreyColor: rgba(255, 255, 255, 0.5);
  --white80: #ffffff80;
  --white50: #ffffff50;
  --grey50Rga: rgba(255, 255, 255, 0.5);
  --darkBorderColor: 1px solid rgba(255, 255, 255, 0.1);
}

.profile-tab-custom-container {
  border-radius: 16px;
  height: 100%;
  box-sizing: border-box;
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  border: var(--darkBorderColor);
  flex: 0.6;
  display: flex;
  flex-direction: column;
}
