@font-face {
  font-family: "Poppins-Regular";
  src: url("../../../../Assets/fonts/Poppins-Regular.ttf");
}

:root {
  --black: #000000;
  --white: #ffffff;
  --secondary: #818181;
  --primary: #ffffff;
  --darkblack: #1e1e25;
  --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
  --darkGreyColor: rgba(255, 255, 255, 0.5);
  --white80: #ffffff80;
  --white50: #ffffff50;
  --grey50Rga: rgba(255, 255, 255, 0.5);
}

.details_main_section {
  align-self: stretch;
  border-radius: 16px;
  background-color: #24242d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  box-sizing: border-box;
  gap: 1rem;
}

.details_third_section {
  flex: 0.4;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.3rem;
  text-align: left;
  font-size: 1rem;
}

.details_third_parent_section {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.3rem;
}
.details_border_section {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow-y: auto;
  font-size: 0.75rem;
}
.details_border_parent_section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.details_border_parent_in_section {
  align-self: stretch;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
}
.details_list_text {
  flex: 1;
  font-size: 0.65rem;
  position: relative;
  font-family: "Poppins-Regular";
  font-weight: 400;
  color: white;
}

.details_main_container {
  align-self: stretch;
  flex: 1;
  border-radius: 16px;
  background-color: #24242d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  gap: 0.3rem;
}
.details_list_fourth_div {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.25rem;
}
.details_call_session {
  position: relative;
  font-size: 0.65rem;
  font-family: "Poppins-Regular";
  font-weight: 400;
  color: var(--secondary); /* Assuming Colors.secondary is a CSS variable */
  text-align: center;
}

.details_call_rupis {
  flex: 1;
  text-align: end;
  font-size: 0.65rem;
  position: relative;
  font-family: "Poppins-Regular";
  font-weight: 400;
  color: white; /* Assuming Colors.white is a CSS variable */
}
.details_total_wait_text {
  position: relative;
  font-size: 0.8rem;
  color: var(
    --darkGreyColor
  ); /* Assuming Colors.greyColor50 is a CSS variable */
  font-family: "Poppins-Regular";
  flex: 1;
}
