@font-face {
  font-family: "Poppins-Regular";
  src: url("../../../Assets/fonts/Poppins-Regular.ttf");
}

:root {
  --black: #000000;
  --white: #ffffff;
  --secondary: #818181;
  --primary: #ffffff;
  --darkblack: #1e1e25;
  --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
  --darkGreyColor: rgba(255, 255, 255, 0.5);
  --white80: #ffffff80;
  --white50: #ffffff50;
  --grey50Rga: rgba(255, 255, 255, 0.5);
}

.astrologers {
  flex: 1;
  position: relative;
  font-weight: 500;
}

.down-icon {
  position: relative;
  width: 1.1rem;
  height: 1.1rem;
}

.frame-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frame-group {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  justify-content: flex-start;
  padding: 1rem 1.5rem;
  gap: 1rem;
}

.frame-parent {
  justify-content: center;
}
.frame-parent {
  gap: 1rem;
  font-size: 1.13rem;
}
.astrologers-parent {
  position: absolute;
  top: 2rem;
  /* left: 20.13rem; */
  border-radius: 16px;
  font-size: 2rem;
}
.overview {
  flex: 1;

  position: relative;
}
.overview-wrapper {
  align-self: stretch;
  display: flex;
  padding-bottom: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.total-astrologers {
  position: relative;
  font-weight: 500;
}
.total-astrologers {
  align-self: stretch;
}

.total-astrologers-parent {
  flex: 1;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  gap: 1.5rem;
}

.frame-div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-div {
  align-self: stretch;
  flex-direction: row;
  gap: 1rem;
  font-size: 1rem;
}

.frame-container {
  flex-direction: column;
}
.frame-container {
  align-self: stretch;
  padding: 1rem;
}
.astrologer-inner {
  align-self: stretch;
  flex: 1;
  border-radius: 16px;
  background-color: #25252d;
}

.sessions-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.home-parent,
.sessions-parent {
  flex: 1;
}
.home-parent {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}
.frame-wrapper2 {
  align-self: stretch;
  flex: 1;
  padding: 0 1rem 1rem;
}
.component-17,
.frame-parent1,
.frame-wrapper2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent1 {
  flex: 1;
  background-color: #25252d;
}
.component-17 {
  position: absolute;
  top: 0;
  left: 0;
  height: 83rem;
  font-size: 1.13rem;
  color: #818181;
}
.group-parent,
.status-parent {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}
.status-parent {
  align-self: stretch;
  align-items: center;
  justify-content: center;
  padding: 0.38rem 0.75rem;
  gap: 0.25rem;
  min-width: 4.88rem;
}
.group-parent {
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
}
.astrologers-list-parent,
.frame-parent2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frame-parent2 {
  /* justify-content: center;
  gap: 1rem;
  text-align: center;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.5); */
}
.astrologers-list-parent {
  /* position: absolute;
  top: 20.19rem; */
  /* left: 20.13rem; */
  /* width: 97.88rem; */
  align-self: stretch;
  justify-content: flex-start;
}
.name {
  display: inline-block;
  flex-shrink: 0;
}
.name,
.total-sessions {
  position: relative;
  font-weight: 600;
}
.call-sessions-parent,
.chat-sessions-parent,
.total-sessions-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.call-sessions-parent,
.chat-sessions-parent {
}
.call-sessions-parent {
}
.avg-time-spends-parent,
.ratings-parent,
.total-earnings-parent {
  width: 5.88rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.avg-time-spends-parent,
.total-earnings-parent {
}
.total-earnings-parent {
  justify-content: flex-end;
}
.status1 {
  position: relative;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
}
.line,
.name-parent {
  box-sizing: border-box;
}
.name-parent {
  background-color: #25252d;
  align-self: stretch;
  height: 3.56rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.detailsShow {
  /* background-color: #25252d; */
  align-self: stretch;
  /* height: 3.56rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.line {
  align-self: stretch;
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  height: 0.06rem;
}
.divider {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.indian-man-smiling-cheerful-ex-icon {
  position: absolute;
  margin: 0 !important;
  top: 0.19rem;
  left: 0;
  width: 2.75rem;
  height: 3.13rem;
  object-fit: cover;
  z-index: 0;
}
.indian-man-smiling-cheerful-ex-wrapper {
  border-radius: 87px;
  background-color: #25252d;
  width: 2.63rem;
  height: 2.63rem;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}
.mayank {
  position: relative;
  font-weight: 500;
}
.vaastu-expert {
  position: relative;
  font-size: 0.88rem;
  color: rgba(255, 255, 255, 0.5);
}
.mayank-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.container,
.frame,
.frame-parent6,
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frame-parent6 {
  justify-content: flex-start;
  gap: 0.5rem;
}
.container,
.frame,
.wrapper {
  justify-content: flex-end;
  padding: 0 1.5rem 0 0;
  box-sizing: border-box;
}
.container,
.frame {
  /* width: 9.13rem; */
}
.frame {
  width: 8.63rem;
}
.div8 {
  position: relative;
  letter-spacing: -0.28px;
}
.star-parent {
  width: 5.88rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.25rem;
  text-align: center;
}
.h {
  position: relative;
  display: inline-block;
  width: 10.81rem;
  flex-shrink: 0;
}
.wrapper2 {
  width: 9.38rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1.5rem 0 0;
  box-sizing: border-box;
}
.instance-wrapper,
.online-wrapper {
  display: flex;
  justify-content: center;
}
.online-wrapper {
  border-radius: 46px;
  background-color: rgba(18, 183, 106, 0.1);
  border: 1px solid #12b76a;
  box-sizing: border-box;
  width: 4.34rem;
  height: 1.75rem;
  flex-direction: row;
  align-items: center;
  padding: 0.38rem 1rem;
}
.instance-wrapper {
  align-self: stretch;
  width: 6.88rem;
  flex-direction: column;
  align-items: flex-start;
  max-width: 6.88rem;
  font-size: 0.75rem;
  color: #12b76a;
}
.busy-wrapper,
.frame-parent5 {
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
}
.frame-parent5 {
  /* width: 97.88rem; */
  height: 3.75rem;
  display: flex;
  justify-content: center;
  padding: 1rem;
  /* gap: 1.5rem; */
}
.busy-wrapper {
  border-radius: 46px;
  background-color: rgba(247, 144, 9, 0.1);
  border: 1px solid #f79009;
  width: 6.34rem;
  height: 1.75rem;
  padding: 0.38rem 1rem;
}
.busy-wrapper,
.instance-frame,
.offline-wrapper {
  display: flex;
  justify-content: center;
}
.instance-frame {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  max-width: 6.88rem;
  font-size: 0.75rem;
  color: #f79009;
}
.offline-wrapper {
  border-radius: 46px;
  background-color: rgba(240, 68, 56, 0.1);
  border: 1px solid #f04438;
  box-sizing: border-box;
  height: 1.75rem;
  flex-direction: row;
  align-items: center;
  padding: 0.38rem 1rem;
}
.frame-parent3,
.frame-parent4,
.instance-wrapper2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.instance-wrapper2 {
  align-self: stretch;
  justify-content: center;
  max-width: 6.88rem;
  font-size: 0.75rem;
  color: #f04438;
}
.frame-parent3,
.frame-parent4 {
  justify-content: flex-start;
  font-size: 1.13rem;
}
.frame-parent3 {
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  font-size: 1rem;
}

/* MenuPopup.css */

.custom-menu-popup {
  width: 7rem;

  padding: 0.5rem;
  /* margin-top: 20px; */
}

.custom-menu-popup ul {
  list-style: none;
  padding: 0;
}

.custom-menu-popup li {
  cursor: pointer;
}

.custom-menu-popup-container {
  position: absolute;
  z-index: 999;
  right: 4rem;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.1);
}

.custom-menu-list {
  cursor: pointer;
  font-size: 0.8rem;
  font-family: "Poppins-Regular";
  color: white;
  padding: 0.4rem;
  font-weight: 500;
}

.custom-menu-list:hover {
  background-color: #f0f0f0;
  color: var(--black);
}
