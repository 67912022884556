@font-face {
  font-family: "Poppins-Regular";
  src: url("../../../Assets/fonts/Poppins-Regular.ttf");
}

:root {
  --black: #000000;
  --white: #ffffff;
  --secondary: #818181;
  --primary: #ffffff;
  --darkblack: #1e1e25;
  --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
  --darkGreyColor: rgba(255, 255, 255, 0.5);
  --white80: #ffffff80;
  --white50: #ffffff50;
  --grey50Rga: rgba(255, 255, 255, 0.5);
  --darkBorderColor: 1px solid rgba(255, 255, 255, 0.1);
}

.icon_style_list {
  position: relative;
  width: 1.1rem;
  height: 1.1rem;
  overflow: hidden;
  flex-shrink: 0;
}

.itemTextDisplay {
  font-family: Poppins-Regular;
  font-size: 0.8rem;
  font-weight: 400;
  color: #fff;
}

/* Define a custom style class */
.custom-box-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 1rem;
  color: var(--grey50Rga);
}

.custom-container-dropdown-div {
  border-radius: 8px;
  border: var(--darkBorderColor);
  block-size: border-box;
  width: 9.38rem;
  display: flex;
  z-index: 1;
  padding: 0.5rem 0.5rem;
}

.custom-box-search-div {
  border-radius: 8px;
  border: var(--darkBorderColor);
  box-sizing: border-box;
  width: 15.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 0.5rem;
  gap: 0.5rem;
}

.custom-image {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
}

.customer-container {
  border-radius: 16px;
  border: var(--darkBorderColor);
  overflow: hidden;
  flex: 0.7;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
}

.list-user-div-parent-container-coupon {
  background-color: #25252d;
  height: 3.56rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  align-self: stretch;
  gap: 0.5rem;
}

.modal-main-container {
  z-index: 999;
  background: #393942;
  width: 90%;
  padding: 20px;
  border-radius: 10px;
}

.modal-ic_close {
  align-self: center;
  justify-content: center;
  display: flex;
  height: 1.3rem;
  width: 1.3rem;
  object-fit: contain;
}
.modal-create-btn {
  border-radius: 20px;
  padding: 0.4rem;
  justify-content: center;
  display: flex;
  background: white;
  color: #1e1e25;
}
.modal-cancel-button {
  border-radius: 20px;
  padding: 0.4rem;
  justify-content: center;
  display: flex;
  border: 1px solid white;
  color: #fff;
}

.customer_userCard {
  width: 20%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.customer_userImage {
  position: relative;
  width: 2.63rem;
  height: 2.63rem;
}

.customer_userInfo {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.customer_userName {
  font-size: 1.13rem;
}

.customer_userDetails {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  color: #818181;
}
