@font-face {
  font-family: "Poppins-Regular";
  src: url("../../../Assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../../../Assets/fonts/Poppins-Bold.ttf");
}

:root {
  --black: #000000;
  --white: #ffffff;
  --secondary: #818181;
  --primary: #ffffff;
  --darkblack: #1e1e25;
  --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
  --darkGreyColor: rgba(255, 255, 255, 0.5);
  --white80: #ffffff80;
  --white50: #ffffff50;
  --grey50Rga: rgba(255, 255, 255, 0.5);
}

.title_reset_password {
  color: #fff;
  font-size: 1rem;
  font-family: Poppins-Bold;
}

.notification_send_successfully {
  color: #fff;
  font-size: 0.7rem;
  font-family: Poppins-Regular;
  font-weight: 600;
  padding: 7px 7px;
  margin-bottom: 13px;
  border: 1px solid #12b76a;
  color: #12b76a;
  border-radius: 7px;
}

.sub_title {
  color: var(--white80);
  font-size: 0.7rem;
  margin-bottom: 15px;
  font-family: Poppins-Regular;
}
::-webkit-calendar-picker-indicator {
  filter: invert(24%) sepia(85%) saturate(799%) hue-rotate(356deg)
    brightness(107%) contrast(117%) grayscale(100%);
}

.send_notificartion_container {
  border-radius: 16px;
  border-color: #25252d;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  width: 45rem;
  margin-top: 0.5rem;
  align-self: center;
  padding: 1.5rem;
}

/* background: rgba(57, 57, 66, 1); */
.Usercalendar .react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: rgba(57, 57, 66, 1);
  color: #000;
  border: 0px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  width: 464px;
  border-radius: 5px;
}
.Usercalendar .react-datepicker__header {
  width: 280px;
  background-color: transparent;
  border: none;
}
.Usercalendar .react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: -7px;
  color: white;
  border-width: 2px 2px 0 0;
}
.Usercalendar .react-datepicker__day-name,
.Usercalendar .react-datepicker__day,
.Usercalendar .react-datepicker__time-name {
  color: white;
  border: 1px solid transparent;
  margin: 0.166rem 0.3rem;
}
.Usercalendar .react-datepicker__current-month,
.Usercalendar .react-datepicker-time__header,
.Usercalendar .react-datepicker-year-header {
  margin-top: 0;
  color: white;
  font-weight: normal;
  font-size: 0.944rem;
  font-family: "Poppins-Regular";
}
.Usercalendar
  .react-datepicker__navigation--next--with-time:not(
    .react-datepicker__navigation--next--with-today-button
  ) {
  right: 181px;
}
.Usercalendar .react-datepicker__year-read-view--down-arrow,
.Usercalendar .react-datepicker__month-read-view--down-arrow,
.Usercalendar .react-datepicker__month-year-read-view--down-arrow,
.Usercalendar .react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
}
.Usercalendar .react-datepicker {
  font-family: "Poppins-Regular";
  font-size: 0.9rem;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
}
.Usercalendar .react-datepicker__day--selected:hover,
.Usercalendar .react-datepicker__day--in-selecting-range:hover,
.Usercalendar .react-datepicker__day--in-range:hover,
.Usercalendar .react-datepicker__month-text--selected:hover,
.Usercalendar .react-datepicker__month-text--in-selecting-range:hover,
.Usercalendar .react-datepicker__month-text--in-range:hover,
.Usercalendar .react-datepicker__quarter-text--selected:hover,
.Usercalendar .react-datepicker__quarter-text--in-selecting-range:hover,
.Usercalendar .react-datepicker__quarter-text--in-range:hover,
.Usercalendar .react-datepicker__year-text--selected:hover,
.Usercalendar .react-datepicker__year-text--in-selecting-range:hover,
.Usercalendar .react-datepicker__year-text--in-range:hover {
  background-color: white;
  border: 1px solid white;
  color: #000;
  border-radius: 50px;
}
.Usercalendar .react-datepicker__day--selected,
.Usercalendar .react-datepicker__day--in-selecting-range,
.Usercalendar .react-datepicker__day--in-range,
.Usercalendar .react-datepicker__month-text--selected,
.Usercalendar .react-datepicker__month-text--in-selecting-range,
.Usercalendar .react-datepicker__month-text--in-range,
.Usercalendar .react-datepicker__quarter-text--selected,
.Usercalendar .react-datepicker__quarter-text--in-selecting-range,
.Usercalendar .react-datepicker__quarter-text--in-range,
.Usercalendar .react-datepicker__year-text--selected,
.Usercalendar .react-datepicker__year-text--in-selecting-range,
.Usercalendar .react-datepicker__year-text--in-range {
  border-radius: 1.3rem;
  background-color: white;
  border: 1px solid white;
  color: #000;
}
.Usercalendar .react-datepicker__day:hover,
.Usercalendar .react-datepicker__month-text:hover,
.Usercalendar .react-datepicker__quarter-text:hover,
.Usercalendar .react-datepicker__year-text:hover {
  border-radius: 1.3rem;
  background-color: transparent;
  border: 1px solid;
}
.Usercalendar .react-datepicker__day-names,
.Usercalendar .react-datepicker__week {
  margin-top: 13px;
}
.Usercalendar .react-datepicker__day--keyboard-selected,
.Usercalendar .react-datepicker__month-text--keyboard-selected,
.Usercalendar .react-datepicker__quarter-text--keyboard-selected,
.Usercalendar .react-datepicker__year-text--keyboard-selected {
  border-radius: 1.3rem;
  background-color: white;
  color: black;
}
.Usercalendar .Usercalendar .react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 170px;
}
.Usercalendar .react-datepicker__time-container .react-datepicker__header {
  width: 170px;
  background-color: transparent;
  border: none;
}
.Usercalendar .react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: transparent;
  border-bottom-right-radius: 0.3rem;
  color: white;
}
.Usercalendar .react-datepicker__time-container {
  float: right;
  border-left: none;
  width: 179px;
}
.Usercalendar
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(214, 72, 72, 0.3);
  background-color: rgba(41, 5, 5, 0);
}
.Usercalendar
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list::-webkit-scrollbar {
  width: 0px;
  background-color: rgba(245, 30, 30, 0);
}
.Usercalendar
  .react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input
  input {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 1rem;
}
.Usercalendar
  .react-datepicker__input-time-container
  .react-datepicker-time__caption {
  display: none;
}
.Usercalendar
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  height: 300px !important;
}
.Usercalendar .react-datepicker__input-container input {
  background-color: transparent;
  border: none;
  font-size: 0.8rem;
  color: white;
  padding: 10px 20px;
  width: 100%;
}
.Usercalendar .react-datepicker__input-container input:hover {
  border: none;
  outline: none;
}
.Usercalendar .react-datepicker__input-container input:focus {
  border: none;
  outline: none;
}
.Usercalendar .react-datepicker__input-container input::placeholder {
  color: white;
}
.Usercalendar
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: aliceblue;
  color: black;
}
.Usercalendar
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
  color: black;
}
.Usercalendar
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: white;
  color: black;
  font-weight: bold;
}
.Usercalendar
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle {
  display: none;
}
.Usercalendar
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle {
  display: none;
}
.arrow-button {
  transform: rotate(90deg);
  left: 120px;
  color: white;
  border-width: 2px 2px 0 0;
  position: relative;
  border: 1px solid white;
  height: 22px;
  width: 21px;
  border-radius: 5px;
  top: -48px;
}
.arrow-button1 {
  transform: rotate(270deg);
  left: 120px;
  color: white;
  border-width: 2px 2px 0 0;
  position: relative;
  border: 1px solid white;
  height: 22px;
  width: 21px;
  border-radius: 5px;
  top: -80px;
}
.Usercalendar .react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: 4px;
  color: white;
  border-width: 2px 2px 0 0;
}
.Usercalendar .react-datepicker__time-container {
  display: none;
}
/* radio button */
.meridiem-label {
  margin-right: 10px; /* Add some spacing between radio buttons */
  color: white; /* Default color */
}


/* .meridiem-label span::before {
  content: "";
  position: absolute;
  background-color: transparent;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  display: inline-block;
  border: 5px solid transparent;
  outline: 1px solid white;
} */

.meridiem-label.selected::before {
  color: green; /* Change the color when the radio button is selected */
}
.meridiem-label input {
  opacity: 0;
}
.meridiem-label span::before {
  content: "";
  position: absolute;
  background-color: transparent;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  display: inline-block;
  border: 3px solid rgba(57, 57, 66, 1);
  outline: 1px solid white;
  margin-left: -20px;
  margin-top: 4px;
}
.meridiem-label input:checked + span::before {
  background-color: white;
}




.modal-dropdown-notification {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
}

.modal-dropdown-notification::-webkit-calendar-picker-indicator {
  display: none;
}

