
@font-face {
  font-family: "Poppins-Regular";
  src: url("../../../Assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../../../Assets/fonts/Poppins-Bold.ttf");
}

:root {
    --black: #000000;
    --white: #ffffff;
    --secondary: #818181;
    --primary: #ffffff;
    --darkblack: #1e1e25;
    --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
    --darkGreyColor: rgba(255, 255, 255, 0.5);
    --white80: #ffffff80;
    --white50: #ffffff50;
    --grey50Rga: rgba(255, 255, 255, 0.5);
  }
.searchTextInput {
    background: transparent;
    flex-grow: 1;
    outline: none;
    display: flex;
    border: 0;
    font-size: 0.8rem;
    color: white;
    
  }  
  .searchTextInput::placeholder {
    color: var(--darkGreyColor); /* Change this to your desired color */
  }

  .mainDivTagPassword{
    margin-top: 20px;
    border: 1px solid white;
    border-radius: 6px;
    width: 40vh;
    padding: 10px 15px;
  }
  

  .reset_password_on_click{
    background: #FFF;
    color:#1E1E25;
    cursor: pointer;
    font-size: 0.8rem;
    width: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    padding: 5px 15px;
    border-radius: 30px;
  font-family: Poppins-Regular;
  

  }
.title_reset_password{
  color: #FFF;
  font-size: 1rem;
  font-family: Poppins-Bold;
}
  .password_do_not_match{
    display: flex;
    padding-top: 2px;
    color: red;
    width: 45vh;
    font-size: 0.7rem;
    padding: 10px 15px;
  }

  .title_password_main{
    color: #FFF;
    font-size: 0.8rem;
    margin-bottom: 4px;
    margin-top: 10px;
    font-family: Poppins-Regular;
  }

  .forgot_sub_title{
    color: #FFFFFF80;
    font-size: 0.67rem;
    text-align: center;
    margin-bottom: 4px;
    font-family: Poppins-Regular;

  }