@font-face {
  font-family: "Poppins-Regular";
  src: url("../../../Assets/fonts/Poppins-Regular.ttf");
}

:root {
  --black: #000000;
  --white: #ffffff;
  --secondary: #818181;
  --primary: #ffffff;
  --darkblack: #1e1e25;
  --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
  --darkGreyColor: rgba(255, 255, 255, 0.5);
  --white80: #ffffff80;
  --white50: #ffffff50;
  --white10: #ffffff10;
  --grey50Rga: rgba(255, 255, 255, 0.5);
}

.sound-wave {
  display: flex;
  width: 100%;
  height: 100px;
}

.main-div-custom-style {
  background-color: #1e1e25;
  text-align: left;
  font-size: 1.5rem;
  color: #fff;
  font-family: Poppins, sans-serif; /* Added a fallback font */
  flex: 1;
}

.main-second-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
}

.main-third-container {
  border-radius: 16px;
  background-color: #25252d;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  width: 51.13rem;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
}

.discard-div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border: 1px solid #ffffff10;
  border-radius: 50px;
  padding: 7px 15px;
  font-family: "Poppins-Regular";
  font-size: 0.8rem;
  color: var(--white);
  flex: 1;
}

.sound-wave-bar {
  width: 2px;
  background-color: #007bff;
  margin: 0 1px;
  transition: height 0.1s;
}
.add-inventory-container {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start;
  justify-content: flex-start; */
  gap: 1rem;
}

.Main-Title-Product {
  font-size: 1rem;
  font-weight: 500;
  color: var(--white);
  font-family: Poppins-Regular;
}

.fontSize14Weight400 {
  font-family: Poppins-Regular;
  font-size: 0.7rem;
  color: #818181;
  font-weight: 400;
}

.in-stock-second-Container {
  border-radius: 46px;
  background-color: rgba(18, 183, 106, 0.1);
  border: 1px solid #12b76a;
  block-size: border-box;
  padding: 5px 10px 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  font-weight: 600;
  font-family: Poppins-Regular;
}

.product-description-id {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-family: Poppins-Regular;
  font-weight: 500;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.5);
}

.amountShowProduct {
  font-size: 1rem;
  font-family: Poppins-Regular;
  font-weight: 400;
}

.edit-duplicate-main-div {
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Poppins-Regular;
}

.edit-duplicate-text-div {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: Poppins-Regular;

  padding: 7px 15px 7px 15px;
}

.order-border-main-div {
  flex: 1;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
  padding: 10px 0px 5px 10px;
}

.order-title-font {
  font-family: Poppins-Regular;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--darkGreyColor);
  flex: 1;
  position: relative;
}

.order-title-value-display {
  position: relative;
  font-size: 1.5rem;
  font-family: Poppins-Regular;
}

.product-image-category {
  flex: 1;
  border-radius: 8px;
  background-color: #343443;
  width: 5.69rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.63rem;
  box-sizing: border-box;
}

.product-image-category-image-tag {
  position: relative;
  width: 4rem;
  height: 2.69rem;
  object-fit: contain;
}
.product-big-image-show {
  flex: 1;
  border-radius: 8px;
  background-color: #343443;
  width: 16rem;
  height: 18rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.63rem;
  box-sizing: border-box;
}
.progress-bar {
  width: 60%;
  height: 13px;
  background-color: #ffffff1a;
  border-radius: 10px;
  margin: 10px 0;
}

.progress {
  height: 100%;
  border-radius: 10px;
  background-color: #ff910f; /* Change color as needed */
  transition: width 0.5s ease-in-out;
}

.div-justify-content-set {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-self: stretch;
}

.hide-delete-container {
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
  font-size: 0.75rem;
  font-size: 0.7rem;
  font-weight: 500;
  color: var(--white);
  font-family: Poppins-Regular;
}

.add-inventory-main-div-section {
  border-radius: 16px;
  border-color: #25252d;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  width: 45rem;
  padding: 1.5rem;
}
