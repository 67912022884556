body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.listItemButton {
  color: #818181; /* Default color */
}

.listItemButton.active {
  color: white; /* Color for active link */
  font-weight: 600;
  font-family: "Poppins-Regular";
  font-size: 01rem;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.1);
}

.listItemButtonactive {
  color: #818181; /* Default color */
}

.listItemButtonactive.subitem {
  color: white; /* Color for active link */
  font-weight: 400;
  font-family: "Poppins-Regular";
  font-size: 0.5rem;
}

/* Add this CSS class to disable hover effect */
.listItemButton.no-hover:hover {
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.1);
  /* Add any other styles you want to remove on hover here */
}
