@font-face {
  font-family: "Poppins-Regular";
  src: url("../../../Assets/fonts/Poppins-Regular.ttf");
}

:root {
  --black: #000000;
  --white: #ffffff;
  --secondary: #818181;
  --primary: #ffffff;
  --darkblack: #1e1e25;
  --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
  --darkGreyColor: rgba(255, 255, 255, 0.5);
  --white80: #ffffff80;
  --white50: #ffffff50;
  --grey50Rga: rgba(255, 255, 255, 0.5);
}

.revenueMainDiv {
  flex: 1;
  font-family: "Poppins-Regular";
  font-size: 1rem;
  font-weight: 400;
  color: var(--white);
  position: relative;
}
.astrologerText {
  font-family: "Poppins-Regular";
  font-size: 1rem;
  font-weight: 400;
  color: var(--white);
  position: relative;
}
.astrologerTextValueShow {
  position: "relative";
  font-size: 1rem;
  font-weight: bold;
  font-family: "Poppins-Regular";
}
.lastWeekEndDiv {
  font-family: "Poppins-Regular";
  font-size: 0.8rem;
  font-weight: 600;
  display: "inline-block";
  flex-shrink: "0";
  flex: "1";
  color: var(--white);
  position: relative;
}

.lastWeekEndDivGreyColor {
  font-family: "Poppins-Regular";
  font-size: 0.8rem;
  font-weight: 600;
  flex: "1";
  color: var(--white50);
  position: relative;
}

.dateTimeGreyColorShow {
  font-family: "Poppins-Regular";
  font-size: 0.8rem;
  font-weight: 400;
  flex: 1;
  color: var(--grey50Rga);
  position: relative;
}
.transactionMainName {
  font-family: "Poppins-Regular";
  align-self: "stretch";
  font-size: 0.8rem;
  position: "relative";
  font-weight: bold;
}

.transactionMainId {
  display: "flex";
  flex-direction: "row";
  align-items: "center";
  justify-content: "flex-start";
  gap: 0.5rem;
  font-size: 0.88rem;
  color: var(--grey50Rga);
}

.sessiondetailslis {
  position: "relative";
  display: "inline-block";
  font-family: "Poppins-Regular";
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--white);
}
.showMoreIcon {
  position: "relative";
  width: 0.8rem;
  height: 0.8rem;
  object-fit: contain;
  overflow: "hidden";
  flex-shrink: 0;
}

.downArrowIcon {
  position: "relative";
  width: 1.5rem;
  object-fit: contain;
  height: 1.5rem;
}

.revenueValueDiv {
  align-self: "stretch";
  position: "relative";
  font-size: 2rem;
  font-weight: 600;
  color: var(--white);
  font-family: "Poppins-Regular";
}

.userOverwiewActiveBusyOfflineMain {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.userOverwiewActiveBusyOfflineMainValueText {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem 0rem 0rem 1.5rem;
  font-size: 0.65rem;
  color: var(--secondary);
}

.gradient-background {
  background: linear-gradient(
    to bottom,
    #ff6b6b,
    #ffa07a
  ); /* Replace these colors with your desired gradient colors */
}

.revenueTaxActiveMainDiv {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}

.icon_copy {
  position: "relative";
  width: 1rem;
  height: 1rem;
  object-fit: cover;
  overflow: "hidden";
}

.transaction-list-container {
  max-width: 600px; /* Set your desired maximum width */
  overflow-x: auto; /* Enable horizontal scrolling if content overflows */
  border: 1px solid #ccc; /* Optional: Add a border for visual separation */
  padding: 10px; /* Optional: Add padding for spacing */
}

/* Styling for the list */
.transaction-list {
  display: flex;
  flex-direction: column;
}

/* styles.css */
.scrollable-container {
  max-height: 230px;
  overflow-y: scroll; /* Always show a vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  width: 100%;
  scrollbar-width: none; /* Hide default scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide default scrollbar for IE/Edge */
  position: relative;
}

.scrollable-container::-webkit-scrollbar {
  width: 5px; /* Change the width of the scrollbar as needed */
  background-color: transparent; /* Set the background color of the scrollbar track to transparent */
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: var(
    --tabbackgroundcolor
  ); /* Change the scrollbar thumb color to your desired color */
  border-radius: 50px;
}

.revenueTaxActiveMainDivDropdown {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  block-size: border-box;
  width: 9.38rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.38rem 0.75rem;
  gap: 0.25rem;
  font-size: 1rem;
}

.showMoreMainDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: right;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}
