@font-face {
  font-family: "Poppins-Regular";
  src: url("../../../Assets/fonts/Poppins-Regular.ttf");
}

:root {
  --black: #000000;
  --white: #ffffff;
  --secondary: #818181;
  --primary: #ffffff;
  --darkblack: #1e1e25;
  --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
  --darkGreyColor: rgba(255, 255, 255, 0.5);
  --white80: #ffffff80;
  --white50: #ffffff50;
  --grey50Rga: rgba(255, 255, 255, 0.5);
  --darkBorderColor: 1px solid rgba(255, 255, 255, 0.1);
}

/* Define a custom style class */
.user-profile-custom-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 1rem;
  align-self: stretch;
  border-bottom: var(--darkBorderColor);
}
