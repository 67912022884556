/* CustomDropdown.css */
@font-face {
  font-family: "Poppins-Regular";
  src: url("../../Assets/fonts/Poppins-Regular.ttf");
}

:root {
  --black: #000000;
  --white: #ffffff;
  --secondary: #818181;
  --primary: #ffffff;
  --darkblack: #1e1e25;
  --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
  --darkGreyColor: rgba(255, 255, 255, 0.5);
  --white80: #ffffff80;
  --white50: #ffffff50;
  --grey50Rga: rgba(255, 255, 255, 0.5);
  --borderColor: rgba(255, 255, 255, 0.1);
  --mainBackgroundColor: rgba(30, 30, 37, 1);
}

.custom-dropdown {
  position: relative;
  cursor: pointer;
  flex: 1;
  display: inline-block;
}

.custom-dropdown2 {
  font-family: 'Poppins-Regular';
  font-size: 0.8rem;
  font-weight: 600;
  flex: 1;
  color: var(--white);
  position: relative;
}
.downArrowIcon {
  position: "relative";
  width: 1.5rem;
  object-fit: contain;
  height: 1.5rem;
}

.dropdown-menu {
  border: 1px solid var(--borderColor);
  list-style: none;
  padding: 0;
  position: absolute;
  margin: 0;
  background-color: var(--mainBackgroundColor);
}

.dropdown-menu li {
  padding: 10px;
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--white);
  cursor: pointer;
  z-index: 2;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
  color: var(--black);
}

.custom-dropdown:hover .dropdown-menu {
  display: block;
}



.menuround {
  rotate: 0deg;
}

.validmenuround {
  rotate: 180deg;
}











.dropdown-menu-pagination {
  /* border: 1px solid var(--borderColor); */
  list-style: none;
  padding: 0; /* Removed default padding */
  margin: 0; /* Removed default margin */
  width: 96%;
  /* height: 100px; */
}

.dropdown-menu-pagination li {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--white);
  cursor: pointer;
  z-index: 2;
  /* height: 100px; */
  width: 100%; /* Ensures the li takes the full width */
}

.dropdown-menu-pagination li:hover {
  background-color: #f0f0f0;
  color: var(--black);
}






.pagination-scrollbar-width-zero::-webkit-scrollbar {
  width: 0px; 
  background-color: transparent; 
}
.pagination-scrollbar-width-zero::-webkit-scrollbar-thumb {
  background-color: var(
    --tabbackgroundcolor
  )
}


.pagination-scrollbar-width::-webkit-scrollbar {
  width: 1px; 
  background-color: transparent; 
}
.pagination-scrollbar-width::-webkit-scrollbar-thumb {
  background-color: var(
    --tabbackgroundcolor
  );
  /* border-radius: 50px; */
}



